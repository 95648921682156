<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="500" class="mx-auto">
          <v-card-title primary-title>
            <h4 class="headline mb-0"><b>كل الإشعارات</b></h4>
          </v-card-title>
          <v-card-text>
            <v-divider></v-divider>
            <v-simple-table>
              <template v-slot:default>
                <tbody v-if="notifications.length > 0">
                  <tr v-for="item in notifications" :key="item.idnotification">
                    <td style="font-size:12px">
                      <inline-svg
                        @click="readNotification(item)"
                        v-if="item.read == 0"
                        src="media/svg/icons/Communication/Mail.svg"
                      />
                      <inline-svg
                        @click="readNotification(item)"
                        v-else
                        src="media/svg/icons/Communication/Mail-opened.svg"
                      />
                      <label @click="readNotification(item)">
                        {{ item.message }}</label
                      >
                      <v-icon
                        color="red"
                        style="float:left"
                        text-color="white"
                        small
                        @click="deleteNotification(item.idnotification)"
                      >
                        mdi-delete
                      </v-icon>
                      <br />
                      <small style="float:left">
                        {{ createdDate(item.date) }}
                      </small>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td class="text-center" style="color:gray;font-size:12px;">
                      لا توجد إشعارات لعرضها
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="$router.go(-1)" style="background:gray;" dark>
              <b>رجوع</b>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      notifications: [],
      visit: null
    };
  },
  methods: {
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    async getAllNotifications() {
      this.loading = true;
      await this.ApiService.get(
        "Notifications/GetNotificationByUser?user=" + this.user.id
      )
        .then(res => {
          this.notifications = [];
          res.data.responseData.forEach(el => {
            if (el != null) {
              this.notifications.push(el);
            }
          });
        })
        .catch(() => {});
      this.loading = false;
    },
    async getVisitId(id) {
      await this.ApiService.get("visit/NewVisitsByUser?user=" + this.user.id)
        .then(res => {
          res.data.responseData.forEach(el => {
            if (el.task.idtask == id) {
              this.visit = el;
            }
          });
        })
        .catch(() => {});
    },
    async deleteNotification(id) {
      await this.ApiService.delete("Notifications/DeleteNotification?id=" + id)
        .then(() => {
          this.getAllNotifications();
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#af1616", 2000);
        });
    },
    async readNotification(noti) {
      await this.ApiService.put(
        "Notifications/MarkAsRead?notificationID=" + noti.idnotification
      )
        .then(() => {
          if (noti.type == "NewTask") {
            if (noti.task.typeId == 1) {
              this.getVisitId(noti.task.idtask).then(() => {
                this.$router.push({
                  name: "show-visit",
                  params: { visitId: this.visit.idvisit, visit: this.visit }
                });
              });
            } else
              this.$router.push({
                name: "show-task",
                params: { task: noti.task, taskId: noti.task.idtask }
              });
          } else if (noti.type == "NewMsg")
            this.$router.push({
              name: "chatting",
              params: { taskId: noti.task.idtask }
            });
          else
            this.$router.push({
              name: "show-task",
              params: { task: noti.task, taskId: noti.task.idtask }
            });
        })
        .catch(() => {});
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getAllNotifications();
  }
};
</script>
<style lang="scss"></style>
